@import url(https://fonts.googleapis.com/css2?family=Jua&family=Fredericka+the+Great&family=Kalam&family=Cabin+Sketch:wght@400&family=Caveat+Brush&family=Chewy&family=Courgette&family=Gochi+Hand&family=Macondo&family=Nerko+One&family=Patua+One&family=Permanent+Marker&family=Righteous&display=swap&display=swap);
/* Position the sticky nicely, so it looks better */
html,
body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  font-size: 16px;
  background-color: #f7f9fb;
  background: url("/Background.png");

}
.chat-room-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background: white;
  /* margin: 16px; */
}

.room-name {
  margin-top: 0;
}

.messages-container {
  flex: 1 1;
  min-height: 100px;
  overflow: auto;
  /* border: 1px solid black; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100vh - 30px);
}

.messages-list {
  width: calc(100vw - 250px);
  list-style-type: none;
  padding: 0;
  margin-left:125px;
  z-index: 99;
}

.new-message-input-field {
  height: 100px;
  max-height: 50%;
  font-size: 20px;
  padding: 8px 12px;
  resize: none;
}

.message-item {
  width: 55%;
  margin: 8px;
  padding: 12px 8px;
  word-break: break-word;
  border-radius: 4px;
  color: white;
}

.my-message {
  background-color: rgb(0, 132, 255);
  margin-left: auto;
}

.received-message {
  background-color: #3f4042;
  margin-right: auto;
}

.button-container {
  display: flex;
  width: 100%;
}

/* .delete-icon {
  position: absolute;
  top: 0;
  right: -10px;
  background: white;
  padding: 10px;
  border-radius: 50%;
} */

.send-message-button {
  font-size: 20px;
  font-weight: 400;
  color: white;
  background: #429bde;
  padding: 12px 12px;
  border: none;
  width: 75%;
  cursor: pointer;
}

.delete-message-button {
  font-size: 20px;
  font-weight: 400;
  color: white;
  background: red;
  padding: 12px 12px;
  border: none;
  width: 25%;
  cursor: pointer;
}

.messages-container,
.new-message-input-field,
.send-message-button {
  border-color: #9a9a9a;
}

ul,
li {
  list-style: none;
  z-index: 99;
}
ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
ul li a {
  text-decoration: none;
  color: #000;
  background: #ff9aa2;
  display: block;
  max-height: 100%;
  max-width: 200px;
  padding: 1em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, 0.7);
  -webkit-transform: rotate(-6deg);
          transform: rotate(-6deg);
  transition: -webkit-transform 0.15s linear;
  transition: transform 0.15s linear;
  transition: transform 0.15s linear, -webkit-transform 0.15s linear;
  line-break: anywhere;
}

ul li:nth-child(even) a {
  -webkit-transform: rotate(4deg);
          transform: rotate(4deg);
  position: relative;
  top: 5px;
  background: #ffdac1;
}
ul li:nth-child(3n) a {
  -webkit-transform: rotate(-3deg);
          transform: rotate(-3deg);
  position: relative;
  top: -5px;
  background: #b5ead7;
}
ul li:nth-child(5n) a {
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  position: relative;
  top: -10px;
}

ul li a:hover,
ul li a:focus {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, 0.7);
  -webkit-transform: scale(1.25);
          transform: scale(1.25);
  position: relative;
  z-index: 5;
}

ul li {
  margin: 1em;
}

h2 {
  font-family: "Kalam", cursive;
}

.react-confirm-alert-body {
  width: 80% !important;
  margin: auto;
}

.bottom-image {
  z-index: 9 !important;
  height: 35px;
  position: fixed;
  bottom: 0;
  margin-top: 30px;
  width: 100vw;
}

.scroll-div {
  width: 100%;
  background: transparent;
  height: 30px;
  z-index: -1;
  padding-top: -40px;
}

.bottom-logo {
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 12vw;
}

.bottom-logo-right {
  position: absolute;
  bottom: 30px;
  right: 0px;
  width: 12vw;
}

.header-img{
  height: 180px;
  width: 80%;
  display: flex;
  align-self:center
}

/* Media query for mobile view */
@media only screen and (max-width: 768px) {
  .header-img {
    height: 10%; /* Adjust the height for mobile view (for example, 10% more than the default) */
  }
}

.user-label span {
  font-family: "Macondo" !important;
  margin-bottom: 10px;
  font-size: 18px;
  display: inline-block;
  color: #737171;
}

.user-label span:first-letter {
  text-transform: uppercase !important;
}


.header-img {
  width:90%;
  height:auto;
  margin-top: 1.5%;
  max-width: 1200px;
}

@media screen and (max-width: 768px) { /* Adjust the max-width as per your requirements */
  .header-img {
    width: 90%; /* Adjust width for smaller screens */
    height:auto; /* Allow the height to adjust proportionally */
    margin-top: 2%; /* Reduce top margin for smaller screens */
    max-width: none; /* Remove max-width to utilize full screen */
  }
}



.image-container {
  display: flex;
  justify-content: space-between; /* Ensures left and right images stay on their respective sides */
  align-items: flex-start;       /* Aligns images vertically at the top */
}

.header-img-left {
  margin-left: 2%;
  width: 20%;
  height: auto;
  margin-top: 1.5%;
  /* max-width: 1200px; */
}

.header-img-right {
  margin-right: 2%; /* Changed from 'right' to 'margin-right' */
  width: 15%;
  height: auto;
  margin-top: 1.5%;
  /* max-width: 1200px; */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .header-img-left, .header-img-right {
    width: 35%; /* Make images larger on mobile */
  }
}
